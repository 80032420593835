<template>
	<b-row class="div-component my-3">
		<b-overlay
			:show="!ready"
			rounded="sm"
			class="dashboard-overlay w-100"
		>
			<section
				class="w-100 h-100"
			>
				<div>
					<b-row class="section-title">
						<div>
							<div class="title-chart">{{ title }} {{ titleAdd }}</div>
						</div>
					</b-row>
				</div>
				<div class="body-chart">
					<ECharts
						:autoresize="true"
						v-if="ready"
						:options="chartOptions"
						class="echart"
						@click="handleChartClick"
					/>
				</div>
			</section>
		</b-overlay>
		<b-modal
			ref="modalList"
			hide-footer
			:hide-header="hideHeaderModal"
			:title="titleModal"
			size="xl"
			no-overflow
			hide-no-focus
		>
			<div v-if="hideHeaderModal" class="title-modal">
				<div class="title-text-modal">{{titleModal}}</div>
				<div
					class="float-right btn-hover-modal"
					@click="closeModal"
				>
					<xIcon/>
				</div>
			</div>
			<div>
				<div class="mt-5 mb-3">
				</div>
				<div v-if="linhas.length" class="table-responsive">
					<DataTable v-if="!isMobile"
						class="data-table"
						:loading="!ready"
						:colunas="colunas"
						:linhas="linhas"
						:errMsg="errMsg"
						:nosearch="true"
						:nofilters="true"
						name="paymentSchedule"
						:hasPagination="true"
						:noedit="true"
						:acoes="false"
						:selectItem="false"
						:globalAcoes="false"
						:alignColumns="alignColumnsTable"
						scroll
						:state="{
							sortBy: sortTable.sortBy,
							sortAsc: 0,
							query: ''
						}"
						:async="false"
					/>
					<CardTransaction v-else
						:loading="!ready"
						:colunas="colunas"
						:linhas="linhas"
						:errMsg="errMsg"
						:acoes="false"
						:selectItem="false"
						:globalAcoes="false"
						:showSelectItem="false"
						:colsDataCard="colsDataCard"
					/>
				</div>
				<div v-else class="alert alert-warning" role="alert">
					{{ errMsg }}
				</div>
			</div>
		</b-modal>
	</b-row>
</template>

<script>
	import ECharts from "vue-echarts";
	import "echarts/lib/chart/line";
	import "echarts/lib/chart/bar";
	import "echarts/lib/chart/pie";
	import "echarts/lib/component/axis";
	import "echarts/lib/component/tooltip";
	import "echarts/lib/component/dataZoom";
	import "echarts/lib/component/legend";
	import { ChartsService } from "@/services/charts";
	import { numberFormatted, stringToDateUTC, formatterSimple } from "@/helpers/common";
	import DataTable from "@/components/DataTable.vue";
	import CardTransaction from "@/components/CardTransaction.vue";
	import { MONTHS } from "@/helpers/enums";

	const colors = [
		"orange",
		"gray",
		"green",
		"blue",
		"pink",
		"cyan",
		"red",
		"yellow",
		"violet"
	];

	export default {
		components: {
			ECharts,
			DataTable,
			CardTransaction
		},

		props: {
			title: {
				type: String,
				required: false,
				default: ""
			},
			searchDate: {
				type: Date,
				required: false
			},
			showZoom: {
				type: Boolean,
				required: false,
				default: false
			},
			showXAxisLabel: {
				type: Boolean,
				required: false,
				default: false
			},
			showIncome: {
				type: Boolean,
				required: false,
				default: true
			},
			chartKey: {
				type: Number,
				required: true
			},
			showLegend: {
				type: Boolean,
				required: false,
				default: true
			},
			forceUpdate: {
				type: Number,
				required: false,
				default: 0
			},
			filters: {
				type: Object,
				required: false
			},
			type: {
				type: String,
				require: false,
				default: null
			}

		},

		data () {
			return {
				chartsService: new ChartsService(),
				ready: true,
				months: [
					{
						nome: "Janeiro",
						value: 1
					}, {
						nome: "Fevereiro",
						value: 2
					}, {
						nome: "Março",
						value: 3
					}, {
						nome: "Abril",
						value: 4
					}, {
						nome: "Maio",
						value: 5
					}, {
						nome: "Junho",
						value: 6
					}, {
						nome: "Julho",
						value: 7
					}, {
						nome: "Agosto",
						value: 8
					}, {
						nome: "Setembro",
						value: 9
					}, {
						nome: "Outubro",
						value: 10
					}, {
						nome: "Novembro",
						value: 11
					}, {
						nome: "Dezembro",
						value: 12
					}
				],
				monthsSelected: [],
				yearsSelected: [],
				groupingSelected: [],
				typeChartSelected: null,
				categorySelected: [],
				chartOptions: {
					notMerge: true,
					dataZoom: [{
						id: "dataZoomX",
						type: "slider",
						xAxisIndex: [0],
						filterMode: "empty",
						top: "4px",
						show: this.showZoom
					}],
					title: {
						text: ""
					},
					tooltip: {
						trigger: "item",
						formatter: params => `
							${params.marker}
							${params.seriesName} ${params.name}<br>
							<div style="display: flex; justify-content: center;"><span>R$ ${numberFormatted(params.value)}</span></div>
						`
					},
					legend: {
						show: this.showLegend,
						data: [],
						bottom: "0px",
						right: "48px",
						textStyle: {
							color: "black",
							fontFamily: "Roboto Condensed Regular"
						}
					},
					grid: {
						left: "64px",
						right: "48px",
						bottom: "80px",
						top: "48px",
						containLabel: false
					},
					xAxis: {
						type: "category",
						data: [],
						axisLabel: {
							show: this.showXAxisLabel,
							textStyle: {
								color: "black",
								fontFamily: "Roboto Condensed Regular",
								fontSize: 10
							},
							rotate: 20
						}
					},
					yAxis: {
						type: "value",
						axisLabel: {
							show: true,
							textStyle: {
								color: "black",
								fontFamily: "Roboto Condensed Regular",
								fontSize: 10
							},
							formatter: params => `${numberFormatted(params)}`,
							rotate: 0
						}
					},
					series: []
				},
				titleAdd: "",

				hideHeaderModal: true,
				titleModal: "Listagem de ",
				isMobile: false,
				linhas: [],
				errMsg: "Nenhum registro encontrado",
				colunas: [],
				colsDataCard: {},
				alignColumnsTable: [],
				chartDataResponse: [],
				sortTable: {
					sortBy: 0,
					order: -1
				}
			};
		},

		watch: {
			"searchDate" () {
				this.findMediaChart();
			},

			"showIncome" () {
				this.findMediaChart();
			},
			"chartKey" () {
				this.findMediaChart();
			},
			"forceUpdate" () {
				this.findMediaChart();
			},
			"filters" () {
				this.monthsSelected = this.filters.monthsSelected;
				this.yearsSelected = this.filters.yearsSelected;
				this.groupingSelected = this.filters.groupingSelected;
				this.typeChartSelected = this.filters.typeChartSelected;
				this.categorySelected = this.filters.categorySelected;
				this.findMediaChart();
			}
		},

		async mounted () {
			this.checkIfMobile();
			window.addEventListener("resize", this.checkIfMobile);

			if (!this.filters || !this.filters.yearsSelected) {
				const currentYear = ((new Date()).getFullYear()).toString();
				this.yearsSelected = [{
					nome: currentYear,
					value: currentYear
				}];

				this.groupingSelected = [{
					nome: "Categoria",
					value: 1
				}];

				this.typeChartSelected = "bar";
			}

			this.chartOptions.grid.top = this.showZoom ? "64px" : "32px";
			await this.findMediaChart();
		},

		methods: {
			checkIfMobile	() {
				this.isMobile = window.innerWidth <= 768;
			},

			initChartOptions () {
				this.chartOptions = {};
				return this.chartOptions = {
					notMerge: true,
					dataZoom: [{
						id: "dataZoomX",
						type: "slider",
						xAxisIndex: [0],
						filterMode: "empty",
						top: "4px",
						show: this.showZoom
					}],
					title: {
						text: "Ventas de Productos"
					},
					tooltip: {
						trigger: "item",
						formatter: params => `
							${params.marker}
							${params.seriesName} ${params.name}<br>
							<div style="display: flex; justify-content: center;"><span>R$ ${numberFormatted(params.value)}</span></div>
						`
					},
					legend: {
						show: this.showLegend,
						data: [],
						bottom: "0px",
						right: "48px",
						textStyle: {
							color: "black",
							fontFamily: "Roboto Condensed Regular"
						}
					},
					grid: {
						left: "64px",
						right: "48px",
						bottom: "80px",
						top: "48px",
						containLabel: false
					},
					xAxis: {
						type: "category",
						data: [],
						axisLabel: {
							show: this.showXAxisLabel,
							textStyle: {
								color: "black",
								fontFamily: "Roboto Condensed Regular",
								fontSize: 10
							},
							rotate: 20
						}
					},
					yAxis: {
						type: "value",
						axisLabel: {
							show: true,
							textStyle: {
								color: "black",
								fontFamily: "Roboto Condensed Regular",
								fontSize: 10
							},
							formatter: params => `${numberFormatted(params)}`,
							rotate: 0
						}
					},
					series: []
				};
			},

			async findMediaChart () {
				try {
					await this.initChartOptions();
					let months = "";
					let years = "";

					if (this.searchDate) {
						months = [ (this.searchDate.getMonth()) + 1 ];
						years = [ this.searchDate.getFullYear() ];
					} else {
						months = this.monthsSelected.length && this.monthsSelected.map((m) => m.value);
						years = this.yearsSelected.length && this.yearsSelected.map((y) => y.value);
					}

					const filters = {
						months,
						years,
						chartKey: this.chartKey,
						categorys: this.categorySelected.map(cat => cat.value)
					};
					this.ready = true;
					this.chartOptions.series = [];
					this.chartOptions.xAxis.data = [];
					this.chartOptions.legend.data = [];
					this.chartDataResponse = await this.chartsService.mediaChart({filters});
					const chartData = [...this.chartDataResponse];
					const chartDataProcess = [];
					switch (this.chartKey) {
						case 2:
							chartData.map((ele) => {
								const chartRecords = {};
								const month = parseInt(ele.month) - 1;
								const month_char = this.months[month].nome;
								chartRecords.serie = `${month_char} ${ele.year}`;
								chartRecords.expenses = ele.expenses;
								if (this.showIncome) chartRecords.income = ele.income;

								return chartDataProcess.push(chartRecords);
							}, []);

							if (this.type != "pie" || !chartDataProcess.length) {
								this.chartOptions.xAxis.data = chartDataProcess.map(data => data.serie);
								if (this.showIncome) {
									this.chartOptions.series.push({
										name: "Receitas",
										type: this.type || this.typeChartSelected,
										color: colors[2],
										data: chartDataProcess.map(value => value.income),
										label: {
											show: true,
											position: "top",
											fontSize: 9,
											fontFamily: "Roboto Condensed Regular",
											formatter: params => `${numberFormatted(params.value)}`,
											color: "black"
										}
									});
								}
								this.chartOptions.series.push({
									name: "Despesas",
									type: this.type || this.typeChartSelected,
									color: colors[0],
									data: chartDataProcess.map(value => value.expenses),
									label: {
										show: true,
										position: "top",
										fontSize: 9,
										fontFamily: "Roboto Condensed Regular",
										formatter: params => `${numberFormatted(params.value)}`,
										color: "black"
									}
								});
								this.chartOptions.legend.data = this.chartOptions.series.map(serie => serie.name);
							} else {
								this.chartOptions = {
									tooltip: {
										trigger: "item",
										formatter: params => `
											${params.marker}
											${params.seriesName} ${params.name}<br>
											<div style="display: flex; justify-content: center;"><span>R$ ${numberFormatted(params.value)}</span></div>
										`
									},
									legend: {
										orient: "vertical",
										bottom: "30px",
										right: "30px"
									},
									color: [
										colors[0],
										colors[2]
									],
									series: [
										{
											name: chartDataProcess[0].serie,
											type: "pie",
											radius: "50%",
											data: [
												{ value: chartDataProcess[0].expenses, name: "Despesas" },
												{ value: chartDataProcess[0].income, name: "Receitas" }
											],
											emphasis: {
												itemStyle: {
													shadowBlur: 10,
													shadowOffsetX: 0,
													shadowColor: "rgba(0, 0, 0, 0.5)"
												}
											},
											label: {
												show: true,
												fontSize:12,
												fontFamily: "Roboto Condensed Regular",
												color: "black"
											}
										}
									]
								};
							}
							break;
						default:
							this.chart3Group(chartData, chartDataProcess, this.groupingSelected.length && this.groupingSelected[0].value || 1);
							break;
					}
				} catch (err) {
					console.log(err);
					this.$snotify.error(
						"Não foi possível atualizar o gráfico online. Tentando novamente...",
						{ timeout: 3000 }
					);
				}
			},

			chart3Group (chartData, chartDataProcess, grouping) {
				let primaryKey = "";
				let secundaryKey = "";

				switch (grouping) {
					case 1: // Month
						primaryKey = "monthYear";
						secundaryKey = "description";
						break;
					case 2: // cateory
						primaryKey = "description";
						secundaryKey = "monthYear";
						break;
					default:
						primaryKey = "monthYear";
						secundaryKey = "description";
				}

				const primary = [];
				const secundary = [];
				chartData.forEach((ele) => {
					const month = parseInt(ele.month) - 1;
					const month_char = `${this.months[month].nome} ${ele.year}`;
					ele.monthYear = month_char;
				});

				chartDataProcess = chartData.reduce((acc, ele) => {
					const xAxis = ele[primaryKey];

					const xAxisExist = (Object.keys(acc)).includes(xAxis);
					if (!xAxisExist) {
						acc[xAxis] = {};
						acc.idCategoryExpense = ele.categoryExpense?.idCategoryExpense || null;
					}

					const serie = ele[secundaryKey];
					acc[xAxis][serie] = ele.expenses || ele.income;

					const primaryExist = primary.includes(xAxis);
					if (!primaryExist)
						primary.push(xAxis);

					const secundaryExist = secundary.includes(serie);
					if (!secundaryExist)
						secundary.push(serie);

					return acc;
				}, {});

				const chartDataEixo = secundary.reduce((acc, xAxis) => {
					acc[xAxis] = {};
					primary.forEach((serie) => {
						acc[xAxis][serie] = chartDataProcess[serie] && chartDataProcess[serie][xAxis] || "0.00";
					});
					return acc;
				}, {});

				const qtdColors = colors.length;
				let idxColor = 0;
				this.chartOptions.series = [];
				primary.forEach((ele) => {
					this.chartOptions.series.push({
						name: ele,
						type: this.typeChartSelected,
						color: colors[idxColor],
						data: secundary.map(ser => chartDataEixo[ser][ele]),
						label: {
							show: true,
							position: "top",
							fontSize: 9,
							fontFamily: "Roboto Condensed Regular",
							formatter: params => `${numberFormatted(params.value)}`,
							color: "black"
						}
					});
					idxColor = idxColor + 1 < qtdColors ? idxColor + 1 : 0;
				});

				this.chartOptions.xAxis.data = secundary.map(ele => ele);
				this.chartOptions.legend.data = primary.map(ele => ele);
			},

			handleChartClick (item) {
				if (this.chartKey === 2) // Analise Comparativo
					this.listAnaliseComparativo(item);
				else if (this.chartKey === 3) // Despesas por Categoria
					this.listDespesaPorCategoria(item, "categoryExpense");
				else if (this.chartKey === 4) // Receitas por Categoria
					this.listDespesaPorCategoria(item, "categoryReceive");
			},

			async listAnaliseComparativo (item) {
				const mesAno = this.type != "pie" ? item.name : item.seriesName;
				const categoria = this.type != "pie" ? item.seriesName : item.name;
				const monthYear = mesAno.split(" ");

				this.titleModal = `Listagem de ${categoria} - ${mesAno}`;

				const objKey = {
					Receitas: "INCOME",
					Despesas: "EXPENSES"
				};

				const months = [MONTHS[monthYear[0].toUpperCase()]];
				const years = [monthYear[1]];

				const filters = {
					months,
					years,
					chartKey: this.chartKey,
					type: objKey[categoria]
				};
				const listDataRecords = await this.chartsService.listRecordsMediaChart({filters});
				this.linhas = listDataRecords.map(rec => ({
					cols: [
						rec.expenseAccount?.description || rec.incomeAccount?.description || "",
						rec.categoryAccount?.description || rec?.toReceive?.categoryReceive?.description || rec?.transactionPayment?.paymentSchedule?.categoryExpense?.description || "",
						formatterSimple(stringToDateUTC(rec.paymentedDate || rec.date)),
						numberFormatted(rec?.transactionPayment?.paymentSchedule?.paymentAmount || rec?.toReceive?.amount || rec.amount),
						rec.reference || "",
						rec?.transactionPayment?.paymentSchedule?.categoryExpense?.comments || rec.comments
					]
				}));
				this.setColumnsModal(this.chartKey);
				this.$refs.modalList.show();
			},

			async listDespesaPorCategoria (item, model) {
				let categoryExpense = "";
				let monthYear = [];
				const grouping = this.groupingSelected.length && this.groupingSelected[0].value || 1;
				if (grouping === 1 ) {
					categoryExpense = item.name;
					monthYear = item.seriesName.split(" ");
				} else if (grouping === 2 ) {
					categoryExpense = item.seriesName;
					monthYear = item.name.split(" ");
				}

				this.titleModal = `Listagem de ${item.seriesName} - ${item.name}`;

				const idCategoryExpense = this.chartDataResponse.filter(reg => reg.description === categoryExpense)[0]?.[model]?.idCategoryExpense;

				const months = [MONTHS[monthYear[0].toUpperCase()]];
				const years = [monthYear[1]];

				const filters = {
					months,
					years,
					chartKey: this.chartKey,
					idCategoryExpense
				};
				const listDataRecords = await this.chartsService.listRecordsMediaChart({filters});
				this.linhas = listDataRecords.map(rec => ({
					cols: [
						rec.accountSchedule?.description || rec.customer.description || "",
						formatterSimple(stringToDateUTC(rec.date)),
						formatterSimple(stringToDateUTC(rec.paymentDate || rec.toReceiveDate)),
						rec.paymentedDate || rec.receivedDate ? formatterSimple(stringToDateUTC(rec.paymentedDate || rec.receivedDate)) : "",
						numberFormatted(rec.amount),
						rec.reference || "",
						rec.comments
					]
				}));
				this.setColumnsModal(this.chartKey);
				this.$refs.modalList.show();
			},

			closeModal () {
				this.$refs.modalList.hide();
			},

			setColumnsModal (chartType) {
				switch (chartType) {
					case 2:
						this.sortTable = {
							sortBy: 2,
							order: -1
						};
						this.colunas = [
							"Conta",
							"Categoria",
							"Data",
							"Valor",
							"Referência",
							"Observações"
						];
						this.alignColumnsTable = [
							"left",
							"left",
							"left",
							"right",
							"left",
							"left"
						];
						this.colsDataCard = {
							0: {
								idx: 0,
								label: "Conta",
								textAlign: "left",
								cols: 6,
								visible: true
							},
							1: {
								idx: 1,
								label: "Categoria",
								textAlign: "left",
								cols: 6,
								visible: true
							},
							2: {
								idx: 2,
								label: "Data",
								textAlign: "center",
								cols: 3,
								visible: true
							},
							3: {
								idx: 3,
								label: "valor",
								textAlign: "right",
								cols: 3,
								visible: true
							},
							4: {
								idx: 4,
								label: "Referência",
								textAlign: "left",
								cols: 6,
								visible: true
							},
							5: {
								idx: 0,
								label: "",
								textAlign: "left",
								cols: 3,
								visible: false
							},
							6: {
								idx: 5,
								label: "Observações",
								textAlign: "left",
								cols: 12,
								visible: true
							},
							7: {
								idx: null,
								label: "",
								textAlign: "left",
								cols: 6,
								visible: false
							}
						};
						break;
					case 3:
						this.sortTable = {
							sortBy: 3,
							order: -1
						};
						this.colunas = [
							"Conta",
							"Data Lançamento",
							"Data Agendamento",
							"Data Pagamento",
							"Valor",
							"Referência",
							"Observações"
						];
						this.alignColumnsTable = [
							"left",
							"left",
							"left",
							"left",
							"right",
							"left",
							"left"
						];
						this.colsDataCard = {
							0: {
								idx: 0,
								label: "Conta",
								textAlign: "left",
								cols: 12,
								visible: true
							},
							1: {
								idx: null,
								label: "",
								textAlign: "left",
								cols: 6,
								visible: false
							},
							2: {
								idx: 1,
								label: "Data Lançamento",
								textAlign: "center",
								cols: 3,
								visible: true
							},
							3: {
								idx: 2,
								label: "Data Agendamento",
								textAlign: "center",
								cols: 3,
								visible: true
							},
							4: {
								idx: 3,
								label: "Data Pagamento",
								textAlign: "center",
								cols: 3,
								visible: true
							},
							5: {
								idx: 4,
								label: "Valor",
								textAlign: "right",
								cols: 3,
								visible: true
							},
							6: {
								idx: 5,
								label: "Referencia",
								textAlign: "left",
								cols: 6,
								visible: true
							},
							7: {
								idx: 6,
								label: "Observações",
								textAlign: "left",
								cols: 6,
								visible: true
							}
						};
						break;
					case 4:
						this.sortTable = {
							sortBy: 3,
							order: -1
						};
						this.colunas = [
							"Cliente",
							"Data Lançamento",
							"Data Agendamento",
							"Data Recebimento",
							"Valor",
							"Referência",
							"Observações"
						];
						this.alignColumnsTable = [
							"left",
							"left",
							"left",
							"left",
							"right",
							"left",
							"left"
						];
						this.colsDataCard = {
							0: {
								idx: 0,
								label: "Cliente",
								textAlign: "left",
								cols: 12,
								visible: true
							},
							1: {
								idx: null,
								label: "",
								textAlign: "left",
								cols: 6,
								visible: false
							},
							2: {
								idx: 1,
								label: "Data Lançamento",
								textAlign: "center",
								cols: 3,
								visible: true
							},
							3: {
								idx: 2,
								label: "Data Agendamento",
								textAlign: "center",
								cols: 3,
								visible: true
							},
							4: {
								idx: 3,
								label: "Data Recebimento",
								textAlign: "center",
								cols: 3,
								visible: true
							},
							5: {
								idx: 4,
								label: "Valor",
								textAlign: "right",
								cols: 3,
								visible: true
							},
							6: {
								idx: 5,
								label: "Referencia",
								textAlign: "left",
								cols: 6,
								visible: true
							},
							7: {
								idx: 6,
								label: "Observações",
								textAlign: "left",
								cols: 6,
								visible: true
							}
						};
						break;

				}
			}
		}
	};
</script>

<style scoped>
	.section-title {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: aliceblue;
		padding-top: 1rem !important;
		padding-bottom: 3px !important;
		padding: 3px 10px;
		margin-bottom: 0px;
		margin-left: 0px;
		width: 100%;
	}
	.div-component {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.body-chart {
		width: 100%;
		height: 31rem;
		min-height: 150px;
		display: flex;
		justify-content: center;
	}

	.echart {
		width: 100%;
		background-color: aliceblue;
		height: auto;
	}

	.style-select {
		color: #28A745;
		border-color: #28A745;
		font-family: "Roboto Condensed Regular";
		font-size: 0.875rem;
	}

	.title-chart {
		font-size: 1.7rem;
		font-family: "Roboto Condensed Regular";
	}

	.select-options {
		min-width: 130px;
		max-width: 200px;
		width: 20%;
		margin-right: 5px;
	}

	.section-select {
		display: flex;
		align-items: center;
		background-color: aliceblue;
		padding-bottom: 1rem !important;
		padding: 0px 8px;
		margin-bottom: 0px;
		margin-left: 0px;
		width: 100%;
		justify-content: right;
	}

@media (max-width: 720px) {
	.title-chart {
		font-size: 1.2rem;
	}

	.body-chart {
		height: 16rem;
	}

	.style-select {
		font-size: 0.575rem;
	}
}

@media (max-width: 370px) {
	.title-chart {
		font-size: 1rem;
	}

	.body-chart {
		height: 12rem;
	}

	.style-select {
		font-size: 0.525rem;
	}

}

@media (max-width: 325px) {
	.title-chart {
		font-size: 0.9rem;
	}

	.body-chart {
		height: 10rem;
	}

	.style-select {
		font-size: 0.5rem;
	}

}

@media (max-width: 310px) {
	.title-chart {
		font-size: 0.8rem;
	}

	.body-chart {
		height: 9rem;
	}

	.style-select {
		font-size: 0.4rem;
	}

}

	@keyframes vertical-slide {
		from {
			overflow-y: clip;
			height: 0%;
		}

		to {
			overflow-y: none;
		}
	}

</style>
